import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/sklep-b2b-questy.jpg";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    porownanie: file(relativePath: { eq: "sklep-b2b-questy-b2c.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    lejekszanssprzedazy: file(
      relativePath: { eq: "lejek-szans-sprzedazy-crm.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    konwersjalejka: file(
      relativePath: { eq: "konwersja-lejka-raportowanie-crm.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    aktywnosc: file(
      relativePath: { eq: "raport-sprzedazy-aktywnosc-handlowcow.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    rankingkontrahentow: file(
      relativePath: { eq: "ranking-kontrahentow-crm.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    rankingsprzedawcow: file(relativePath: { eq: "ranking-sprzedawcow.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    kategorieproduktowe: file(
      relativePath: { eq: "ranking-kategorii-produktowych.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie3: file(relativePath: { eq: "ranking-sprzedawcow.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Sklepb2b = ({ data }) => {
  return (
    <Artykul
      title="Sklep B2B - jak zacząć?"
      articleImage={obrazekArtykulu}
      articleImageAlt="sklep b2b"
      metaTitle="Sklep B2B - Jak przygotować ofertę dla klienta hurtowego?"
      metaDescription="Sklep B2B - czy platforma sprzedażowa jest dla Ciebie? ✅ Jak przygotować ofertę w sklepie B2B dla klienta hurtowego? ✅ Sklep B2B zintegrowany z systemem ERP ✅ Wypróbuj BEZPŁATNIE sklep B2B!"
    >
      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Jak przygotować ofertę dla klienta hurtowego?
      </h3>
      <br />
      <p>
        Zastanawiasz się jak zacząć sprzedawać hurtowo w Internecie? Chcesz
        zobaczyć jak w praktyce mogłaby wyglądać Twoja oferta produktowa w
        sklepie B2B? Przeczytaj ten artykuł. Dowiesz się z niego:
      </p>
      <p>
        <ul>
          <li>jak może wyglądać sprzedaż hurtowa w sklepie B2B,</li>
          <li>
            na co warto zwrócić uwagę przygotowując ofertę online dla klienta
            hurtowego, aby zachęcić go do zakupów w nowym kanale,
          </li>
          <li>jak zwiększyć wartość sprzedaży i oszczędzić czas handlowców.</li>
        </ul>
      </p>

      <div className="text-block">
        <br />
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="kAvTggS0wFc"
          title="Sklep B2B: jak zacząć sprzedawać w Internecie hurtowo"
        />
      </div>

      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Sklep B2B a sklep B2C
      </h3>
      <p>
        Aby sprawnie przejść do obszarów, na które warto zwrócić uwagę w sklepie
        B2B, przypomnijmy sobie pokrótce najważniejsze różnice między sklepem
        B2Ba B2C:
      </p>
      <Lightbox
        style={{
          maxWidth: 700,
          margin: "0 auto",
        }}
        images={[data.porownanie]}
        alts={["sklep B2B a sklep B2C - porównanie"]}
      />
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz otworzyć swój sklep B2B?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do darmowej 30-dniowej wersji próbnej platformy B2B

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i rozpocząć sprzedaż w sklepie B2B
            </Link>
          </div>
          </div>
    </section>
      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Elementy oferty produktowej w sklepie B2B, które mają znaczenie
      </h3>
      <p>
        Znając różnice, prościej będzie wskazać kluczowe elementy, które mają
        wpływ na odbiór Twojej oferty produktowej w sklepie B2B.
      </p>
      <p>
        <strong>1. Dostęp do oferty - nowoczesny sklep B2B umożliwia:</strong>
      </p>
      <p>
        <ul>
          <li>
            po zalogowaniu (ta sytuacja oznacza, że albo przekazałeś
            kontrahentowi dostęp albo sam założył konto, które administrator
            musiał zatwierdzić). Jest to częsty wybór jeżeli dystrybutorowi
            zależy na udostępnieniu oferty tylko zatwierdzonym kontrahentom.
          </li>
          <li>
            bez zalogowania (z możliwością np. ukrycia cen), gdy zależy
            dystrybutorowi na zainteresowaniu potencjalnych nowych kontrahentów
            swoją ofertą.
          </li>
        </ul>
      </p>

      <p>
        <strong>2. Prezentacja oferty produktowej</strong>
      </p>
      <p>
        W sklepie B2B często używanym widokiem jest np. lista produktów. Weźmy
        za przykład dystrybucję nakrętek metalowych, śrubek - zdjęcia produktów
        nie mają kluczowego znaczenia. W przypadku produktów, których wygląd
        produktu jest istotny - sklep B2B oferuje atrakcyjny widok galerii
        produktów z dużymi zdjęciami.
      </p>
      <p>Na co zwrócić uwagę podczas przygotowania oferty produktowej?</p>
      <p>
        <ul>
          <li>
            zadbaj o odpowiednie kategorie (nazwy kategorii pobierane są z
            systemu ERP),
          </li>
          <li>
            uzupełnij nazwy produktów, myśląc o tym jak mogą być wyszukiwane,
          </li>
          <li>dodaj opisy oraz atrakcyjne zdjęcia,</li>
          <li>
            jeżeli jest to istotne - nadaj dostęp do danej kategorii produktów
            dla konkretnego profilu klienta (jedna platforma - sprzedaż różnych
            produktów)
          </li>
          <li>
            klient widzi swoją cenę indywidualną, ale możesz mu też pokazać cenę
            detaliczną (czy sugerowaną cenę sprzedaży), aby podkreślić fakt, że
            kupuje ten produkt taniej.
          </li>
        </ul>
      </p>
      <p>
        <strong>3. Spersonalizowane podejście do współpracy</strong>
      </p>
      <p>
        Dodatkową opcją są indywidualne rabaty (procentowy, na określoną
        wartość, produkt, grupę produktową), darmowa dostawa po przekroczeniu
        ustalonej wartości zakupu.{" "}
      </p>
      <p>
        <strong>4. Zamówienia</strong>
      </p>
      <p>
        Proste dodawanie produktów do koszyka oraz sprawne ponawianie zamówień z
        możliwością edycji pozycji.
      </p>
      <p>
        <strong>5. Wygląd panelu klienta</strong>
      </p>
      <p>
        <ul>
          <li>
            baner reklamowy z opcją linkowania do kategorii produktów /
            landingpage;{" "}
          </li>
          <li>produkty polecane; listy dokumentów;</li>
          <li>
            menu - spersonalizowana struktura menu (oferta, promocje, nowości,
            ogłoszenia).
          </li>
        </ul>
      </p>

      <p>
        <strong>6. Ogłoszenia</strong>
      </p>

      <p>
        Wpływ na odbiór oferty mają także komunikaty jakie komunikaty chcesz,
        aby były przekazywane za pośrednictwem sklepu B2B:
      </p>
      <p>
        <ul>
          <li>dla pracowników</li>
          <li>dla klientów</li>
        </ul>
      </p>
      <p>
        <strong>7. Sprawne wyszukiwanie produktów (Elastic Search)</strong>
      </p>

      <p>
        Jeżeli posiadasz duży sklep B2B, z tysiącami produktów, przyda Ci się
        Elastic Search. Jest to narzędzie do wyszukiwania pełnotekstowego.
      </p>
      <p>Dzięki zastosowaniu tej możliwości:</p>
      <p>
        <ul>
          <li>
            uzyskasz szybsze zwracanie wyników, a wyszukiwanie odbywa się po
            kodzie, nazwie, opisie, a nawet atrybutach tekstowych, które
            samodzielnie możecie dodać dla produktów.
          </li>
          <li>
            wyniki na liście produktów zostaną posortowane na podstawie oceny
            trafności względem zapytania.
          </li>
        </ul>
      </p>

      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Sklep B2B - rozwijaj swoją sprzedaż!
      </h3>
      <p>
        Możliwości sprzedaży hurtowej w internecie są niemal nieograniczone.{" "}
        <strong>
          Sklep B2B może być zarówno prostą platformą jak i dedykowanym
          rozwiązaniem.
        </strong>{" "}
        Jeżeli chcesz dowiedzieć się więcej na temat platform B2B zapraszamy Cię
        do subskrypcji naszego kanału na YouTube lub kontaktu z nami, jeżeli
        chcesz za darmo przetestować możliwości platformy w swojej firmie.</p>
        <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz otworzyć swój sklep B2B?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do darmowej 30-dniowej wersji próbnej platformy B2B

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i rozpocząć sprzedaż w sklepie B2B
            </Link>
          </div>
          </div>
    </section>
        <p>
        Zachęcamy też do przeczytania innych artykułów opisujących możliwości
        sklepu B2B.
      </p>
      <p>
        <ul>
          <li>
            <Link to="/czym-jest-platforma-b2b/">Czym jest platforma B2B?</Link>
          </li>
          <li>
            <Link to="/system-b2b-funkcje/">
              System B2B - przeznaczenie i najważniejsze funkcje
            </Link>
          </li>
          <li>
            <Link to="/wdrozenie-platformy-b2b/">
              5 etapów wdrożenia systemu B2B w organizacji
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Sklepb2b;
